.menu_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: sticky;
    top: 0;
    padding: 10px 0 0 0;
    z-index: 900;
}

.menu{
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 50px;
    max-height: 50px;
}

@media screen and (max-width:1000px){
    .menu{
        max-width: 800px;
    }
}

@media screen and (max-width:800px){
    .menu{
        max-width: 600px;
    }
}

@media screen and (max-width:600px){
    .menu{
        max-width: 400px;
    }
}

@media screen and (max-width:400px){
    .menu{
        max-width: 350px;
    }
}