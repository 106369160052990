.circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border:2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    position:relative;
}

.inside{
    /* absolute bg-green-500 px-2 border-2 border-white top-0 right-[-50%] */
    position: absolute;
    padding: 2px 7px 2px 7px;
    border: 2px solid white;
    top: 0;
    border-radius: 50%;
    left: -50%;
    background-color: rgb(37, 221, 37);
    display: flex;
    justify-content: center;
    align-items: center;
}