.team_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 0 3px 0 3px;
}

.sub_container{
    width: 100%;
}

.center_items{
    display: flex;
    justify-content: center;
    align-items: center;
}
.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    cursor: pointer;
}

.player_name{
    font-size: 14px;
    color: rgb(255, 255, 255);
    /* color: #343A40; */
    font-family: 'Montserrat',sans-serif;
    max-width: 100px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}