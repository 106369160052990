*,*::before,*::after{
  box-sizing: border-box;
  text-decoration: none;
}

body{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat',sans-serif;
  background-color: #E5E5E5;
}

button{
  cursor: pointer;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-width: 0 0 2px 0;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-tabview .p-tabview-panels{
  padding: 20px 5px 20px 5px;
}

.p-dialog-content{
  padding: 15px 0 15px 0 !important;
}

.owl-nav{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.owl-carousel .owl-nav button.owl-prev,.owl-carousel .owl-nav button.owl-next{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
}

.owl-carousel .owl-nav button.owl-prev span{
  color: white !important;
  font-size: 20px !important;
  font-weight: 600px !important;
}



.nav-button.owl-prev,.nav-button.owl-next{
  background-color: transparent !important;
  margin-top: 2px;
}

  li{
    min-width: fit-content;
  }

  li:last-child{
    padding-right: 30px;
  }

.active-tab{
  border:2px solid red !important;
}

@media screen and (max-width:800px) {
  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-width: 0 0 2px 0;
    min-width: 100%;
    justify-content: unset;
    align-items: unset;
  }

  li{
    min-width: fit-content;
  }
}

@media screen and (max-width:600px){
  .p-tabview-nav-container{
    width: 100%;
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
  }
  
  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-width: 0 0 2px 0;
    display: flex;
    width: 350px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}