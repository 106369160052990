.container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.button_container{
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.tab_container{
    width: 100%;
    background-color: #fff;
    min-height: 500px;
    border-radius: 3px;
}

.match_container{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 10px 0 10px 0;
}

.tab_container{
    width: 100%;
    background-color: #fff;
    min-height: 500px;
    border-radius: 3px;
}

.prediction_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 10px 60px rgba(0,0,0,0.1);
    padding: 30px 25px 30px 25px;
}

.questions_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}

/* 0052EA dark blue
A7C5FD light blue

F1F6FD faded blue
*/
.question{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    background-color:#F1F6FD ;
    padding: 20px;
    border: 1px solid #A7C5FD;
    border-left: 4px solid #0052EA;
}

.question_title{
    width: 100%;
    text-align: left;
    font-size: 18px;
    color: #343A40;
    font-weight: 500;
    font-family: 'Montserrat',sans-serif;
    padding: 0;
    margin: 0;
}

.selector{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;    
    gap: 10px;
}

.highlight{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    color: rgb(238, 34, 34);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.text_danger_glow{
    color: #ff4141;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
}

.blink {
    animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }


@media screen and (max-width:800px) {
    .match_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}