.loader_container{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader{
    width: 300px;
    height: 350px;
}

.loader_animation{
    width: 100%;
    height: 100%;
    object-fit: contain;
}