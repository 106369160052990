.container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0 20px 0;
}

.table{
    width: 100%;
}

.team_info{
    max-width: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.text{
    color: #343A40;
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
}

.logo{
    width: 45px;
    height: 40px;
    object-fit: contain;
}

@media screen and (max-width:1000px){
    .logo{
        width: 25px;
        height: 25px;
        object-fit: contain;
    }
    .text{
        font-size: 12px;
    }
}