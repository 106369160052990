.container{
    /* background: #fff;    */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 30px 0;
    margin: 30px 0 30px 0;
    border-radius: 3px;
    /* min-height: 100vh; */
}

.sub_container{
    background-color: #fff;
  padding: 20px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 50px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.6);
  border-radius: 10px;
  overflow: hidden;
}

.title{
    font-size: 30px;
    /* color: #639fa4; */
    font-family: 'Montserrat',sans-serif;
    font-weight: 900;
    text-decoration:wavy;
    /* background: linear-gradient(to right, #0f3b3f 0%, #05cbdd 100%); */
    background: linear-gradient(270deg, #41324F 0%, #65A0A5 24.13%, #41324F 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.login_form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0;
}

.form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.login_btn {
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 600;
    background: #639fa4;
    outline: none;
    border: none;
    border-radius: 6px;
    color: white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    cursor: pointer;
    letter-spacing: 1px;
    transition: all 0.3s;
}

.login_btn:hover {
    background: #194448;
}

.login_btn:active{
    transform: scale(1.1);
}

.image_main_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_container{
    width: 300px;
    height: 300px;
}

.login_image{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.link{
    font-size: 18px;
    color: #194448;
    font-family: 'Montserrat',sans-serif;
    text-decoration: underline;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width:800px){
    .sub_container{
        padding: 20px 50px 20px 50px;
        width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        gap: 0px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.6);
        border-radius: 10px;
      }
      .login_form{
        margin-top: -60px;
        padding-bottom: 40px;
      }
}

@media  screen and (max-width:600px) {
    .sub_container{
        padding: 20px 10px 20px 10px;
      }
    .image_container{
        width: 200px;
        height: 200px;
    }
    .login_form{
        margin-top: -40px;
        padding-bottom: 20px;
      }
}

@media screen and (max-width:300px){
    .image_container{
        width: 150px;
        height: 150px;
    }
    .title{
        font-size: 20px;
    }
}
