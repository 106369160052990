.matches_container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    align-items:flex-start;
    align-content: stretch;
    padding: 30px 0 10px 0;
}

@media screen and (max-width:1150px){
    .matches_container{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:500px){
    .matches_container{
        grid-template-columns: 1fr;
    }
}