.carousel_container{
    width: 100%;
    padding: 10px 10px 10px 10px;
}

.sub_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75vw;
}

.carousel {
    overflow-x: hidden;
  }

.carousel_item{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #F9FAFB;
    border: 1px solid #e5e7eb;
    padding: 10px;
    height: fit-content;
    overflow-x: hidden; 
}

@media screen and (max-width:1000px){
    .sub_container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80vw;
    }
}