.match_container{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 0 30px 0;
}


@media screen and (max-width:800px) {
    .match_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}