.match_flex_col{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 10px 60px rgba(0,0,0,0.1);
    padding: 10px 15px 10px 15px;
    max-height:185px;
    min-height: 185px;
    cursor: pointer;
}

.match_flex_col.height_adjustment{
    max-height:160px;
    min-height: 160px;
}
.match_status{
    min-height: 10px;
    max-height: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.status_text{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text_danger_glow{
    color: #ff4141;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
}

.blink {
    animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }

.match_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
    flex-direction: row;
    gap: 15px;
}

.match_sub_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
}

.home_team_section,.away_team_section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 15px;
}

.home_team_logo_name,.away_team_logo_name{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 15px;
}

.home_logo_container,.away_logo_container{
    width: 40px;
    height: 40px;
}

.home_logo,.away_logo{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.name{
    font-size: 14px;
    /* max-width: 80px;
    white-space: nowrap; 
    overflow: hidden;  
    text-wrap: nowrap;
    text-overflow: ellipsis; */
}

.match_date_container{
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-width: 120px; */
    gap: 10px;
}

.line{
    height:100px;
    width: 1px;
    /* background-color: #343a40a9; */
    background-color: #00000063;
}

.match_btns{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 10px;
    gap: 10px;
}

.date,.time{
    font-size: 14px;
}

.monitor_btn{
    padding: 8px 10px 8px 10px;
    outline: none;
    border: none;
    box-shadow: 0 14px 50px rgba(0,0,0,0.3);
    /* background-color: #FF2929; */
    background-color: #2978ff;
    color:white;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
}
.monitor_btn:hover{
    background-color: #0858e2;
}
.monitor_btn:active{
    transform: scale(1.1);
}

/* .match_date_time{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
} */

@media screen and (max-width:800px){
    .match_container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
    }
    .match_flex_col{
        max-height:270px;
        min-height: 270px;
    }
    .match_flex_col.height_adjustment{
        max-height:250px;
        min-height: 250px;
    }
    .match_date_container{
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 2px;
        width: 100%;
    }
    .line{
        height:1px;
        width: 100%;
        background-color: #00000063;
    }
}

@media screen and (max-width:500px){
    .match_flex_col{
        max-height:unset;
        min-height: unset;
    }
    .match_btns{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: unset;
        bottom: unset;
    }
}