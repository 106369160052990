.container{
    width: 100%;
    margin: 30px 0 10px 0;
    width: 100%;
    background-color: #fff;
    min-height: 500px;
    border-radius: 3px;
    /* overflow-x: hidden; */
}

.leaderboard_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.heading{
    font-size: 16px;
    color: #343A40;
    font-family: 'Montserrat',sans-serif;
    padding: 0;
    margin: 0;
}

.info_container{
    /* background-color: #FFF6F8;
    border: 1px solid #FFA7A0;
    border-left: 3px solid #F44336; */
    background-color: #d7f1da;
    border: 1px solid #9efc90;
    border-left: 3px solid #36f436;
    padding: 20px;
    width: 350px;
}