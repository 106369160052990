.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px; 
    background-color: #f5f5f5; 
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25); 
    padding: 16px; 
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
  }

  .modal_title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title{
    font-size: 16px;
    color: #343A40;
  }


  .input_box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .text{
    font-size: 16px;
    color: #343A40;
    margin: 0;
    padding: 0;
  }

  .message_box{
    width: 100%;
    background-color: #F9FAFB;
    border: 2px solid #e5e7eb;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  }

  .message{
    color: #343A40;
  }

  .submit_btn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit{
    padding: 10px 20px 10px 20px;
    background-color: rgb(29, 98, 248);
    color: white;
    font-size: 18px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  }